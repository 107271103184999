import { createSignal } from "solid-js";

import UBoolean from "@repo/utils/UBoolean";

export default function createUpdate() {
  const [listen, update] = createSignal(undefined, {
    equals: UBoolean.returnsFalse,
  });

  return Object.assign(
    () => {
      update();
    },
    {
      listen,
    },
  );
}
